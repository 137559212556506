@import 'variables.scss';

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $yellow;
    z-index: 999;
    transition: opacity 1s ease-out;
    /* Smooth fade out effect */

    img {
        max-width: 100px;
    }
}

.loader.fadeout {
    opacity: 0;
    transition: opacity 0.5s ease;
    visibility: hidden;
}

.scrollDown {
    position: fixed;
    bottom: 200px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    flex-direction: column;
    gap: 15px;
    font-family: $body-font;
    animation: float 3s ease-in-out infinite;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.scrollDown.fade-out {
    opacity: 0;
    pointer-events: none; // Prevents interactions when invisible
}

@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

nav {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    width: max-content;
    z-index: 99999999999;
    max-width: 600px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &.hidden {
        transform: translateX(-50%) translateY(100px); // Moves it off-screen
        opacity: 0;
        pointer-events: none;
    }

    &.visible {
        transform: translateX(-50%) translateY(0); // Slides it back
        opacity: 1;
        pointer-events: auto;
    }

    @media screen and (max-width: 790px) {
        top: 0;
        bottom: auto;
        height: 76px;
        background-color: $grey;
        max-width: none;
    }

    .background {
        width: 100%;
        max-width: 600px;


        @media screen and (max-width: 790px) {
            display: none;
        }
    }

    .navContent {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        padding-bottom: 5px;

        img {
            max-width: 80px;

            @media screen and (max-width: 1200px) {
                width: 100%;
                max-width: 60px;
            }
        }

        .downloadWrap {
            display: flex;
            width: 100%;
            justify-content: end;
            gap: 10px;
            align-items: center;
            color: $white;
            font-family: $body-font;

            label {
                font-size: 16px;
            }

            img {
                max-width: 120px;
            }
        }
    }
}



.pageWrap {

    .mobileOverflow {}

    .riveWrap {
        height: 5000px;
        background-color: $yellow;
        display: flex;
        justify-content: center;

        div {
            transform: scale(1) !important;
        }

    }



    .contentWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        // margin-top: -100px;

        .cloudTop {
            display: inline-block;
            width: 100%;
            background-color: $yellow;
            position: relative;

            .downloadMain {
                width: 100%;
                display: flex;
                justify-content: center;
                /* Horizontally center the content */
                align-items: center;
                /* Vertically center the content */
                margin: auto;
                max-width: 400px;
                position: absolute;
                top: 20%;
                /* Adjust to vertically center the element */
                left: 50%;
                /* Position from the left side */
                transform: translate(-50%, -50%);
                /* Offset the element to truly center it */

                @media screen and (max-width: 1200px) {
                    top: -5%;
                    padding: 20px;
                    box-sizing: border-box;
                }

                .downloadLinks {
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                    align-items: center;

                    a {
                        max-width: 200px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            div {
                @media screen and (max-width: 540px) {
                    height: 200px !important;
                }
            }
        }

        .section {
            display: flex;
            width: 100%;
            padding: 30px 0px;
            max-width: 1400px;
            box-sizing: border-box;

            @media screen and (max-width: 1200px) {
                padding: 30px 60px;
                overflow: hidden;
            }


            .block {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;



                h2 {
                    font-family: 'Lastik';
                    color: $white;
                    font-size: 115px;
                    width: 100%;
                    font-weight: bold;


                    @media screen and (max-width: 1200px) {
                        font-size: 56px;
                        line-height: 56px;
                    }


                    span {
                        color: #FFD331;
                    }

                }

                .imagesBlock {
                    display: flex;
                    flex-direction: column;

                    .row {
                        width: 100%;
                        display: flex;

                        @media screen and (max-width: 1200px) {
                            flex-direction: column;

                        }

                        .image {
                            width: 100%;
                            text-align: center;


                            img {
                                width: 100%;
                                position: absolute;
                                max-width: 380px;

                                @media screen and (max-width: 1200px) {
                                    position: static;

                                }
                            }


                            .image-1 {
                                left: 0px;
                                z-index: 2;
                                top: 0;
                            }

                            .label {
                                display: block;
                                width: 225px;
                                position: absolute;
                                z-index: 3;
                                width: 225px;
                                height: 138px;

                                @media screen and (max-width: 1200px) {}

                                img {
                                    position: static;
                                }

                                label {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    z-index: 5;
                                    transform: translate(-50%, -50%) rotate(14deg);
                                    font-family: 'DM Sans';
                                    font-size: 23px;
                                    font-weight: 600;
                                    color: $yellow;

                                }
                            }





                            .label-1 {
                                top: 0;
                                left: -100px;


                                @media screen and (max-width: 1200px) {
                                    top: 150px;
                                }


                            }

                            .label-2 {
                                top: 0;
                                right: 0px;
                                // transform: rotate(-24deg) !important;

                                @media screen and (max-width: 1200px) {
                                    top: -50px;
                                    right: -100px;
                                }
                            }

                            .label-3 {
                                bottom: 0;
                                left: -100px;
                                // transform: rotate(-10deg) !important;


                                @media screen and (max-width: 1200px) {
                                    left: -50px;
                                    bottom: -30px;
                                }
                            }

                            .label-4 {
                                bottom: 110px;
                                right: 230px;
                                // transform: rotate(-30deg) !important;

                                @media screen and (max-width: 1200px) {
                                    right: -100px;
                                }


                            }



                            .image-2 {
                                right: 50px;
                                top: 40px;
                                z-index: -1;
                            }

                            .image-3 {
                                right: 400px;
                                bottom: 100px;
                            }

                            .image-4 {
                                right: 0px;
                                bottom: 40px;

                            }
                        }


                    }
                }



                .clock {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    h2 {
                        display: flex;
                        gap: 15px;
                        justify-content: center;
                        align-items: center;
                        line-height: 100px;
                        margin: 0;

                        &:first-of-type {
                            span {
                                color: $white;
                            }
                        }

                        &:last-of-type {
                            span {
                                color: $white;
                            }
                        }


                        span {
                            color: $yellow;
                            display: inline-block;
                        }

                        .centerDots {
                            height: 120px;
                        }
                    }
                }

                .clockWrap {
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    .clock {
                        max-width: 300px;
                        position: absolute;
                        right: 100px;
                        bottom: 0;
                    }

                    div {
                        @media screen and (max-width: 1200px) {
                            position: static !important;

                        }
                    }
                }

                .pointsWrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 950px;
                    text-align: center;
                    flex-direction: column;
                    position: relative;
                    padding: 150px 0;

                    .textWrap {
                        position: relative;
                        width: 100%;
                        display: block;

                        h2 {
                            margin-bottom: 0;
                        }

                        .awards {
                            position: absolute;
                            top: 0;
                            display: flex;
                            flex-direction: column;
                            z-index: -1;

                            img {
                                max-width: 225px;
                            }

                        }

                        .awards-1 {


                            @media screen and (max-width: 800px) {
                                left: -50px !important;
                                top: -100px;

                                img {
                                    max-width: 150px;
                                }
                            }

                        }

                        .awards-2 {
                            right: 0;

                            @media screen and (max-width: 800px) {
                                top: -100px;
                                right: -60px !important;

                                img {
                                    max-width: 150px;

                                    &:last-of-type {
                                        margin-top: 120px;
                                    }
                                }
                            }

                        }




                    }


                    .pointsBar {
                        display: flex;
                        gap: 20px;
                        align-items: end;
                        position: absolute;
                        bottom: -150px;
                        align-items: center;
                        justify-content: center;

                        @media screen and (max-width: 1200px) {
                            width: 100%;


                            div {
                                width: 400px !important;
                            }

                        }


                    }

                    .backgroundImages {
                        width: 100%;
                        display: block;
                        position: absolute;
                        top: 20%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: -1;

                        .images {
                            position: absolute;
                            top: 0;
                            display: flex;
                            flex-direction: column;
                            z-index: -1;

                            img {
                                max-width: 250px;
                            }

                            &:first-of-type {
                                left: 0;

                                @media screen and (max-width: 1200px) {
                                    left: -70px !important;

                                }

                                img {
                                    &:first-of-type {
                                        position: absolute;
                                        top: 0;
                                    }

                                    &:last-of-type {
                                        position: absolute;
                                        top: 200px;
                                        left: -100px;
                                    }
                                }

                            }

                            &:last-of-type {
                                right: 0;

                                @media screen and (max-width: 1200px) {
                                    right: -70px !important;

                                }

                                img {
                                    &:first-of-type {
                                        position: absolute;
                                        top: 0;
                                        left: -200px;
                                    }

                                    &:last-of-type {
                                        position: absolute;
                                        top: 200px;
                                        right: -150px;
                                    }
                                }
                            }
                        }



                    }

                    .backgroundimage {
                        width: 600px;
                        margin: auto;
                    }
                }

                .coachesWrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 1400px;
                    text-align: center;
                    flex-direction: column;
                    position: relative;
                    padding-bottom: 100px;
                    box-sizing: border-box;

                    h2 {
                        font-family: 'Moti';
                        font-size: 52px;

                    }

                    .coaches {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 50px;
                        width: 100%;


                        @media screen and (max-width: 1200px) {
                            flex-direction: column;

                        }

                        img {}

                    }


                }

            }
        }

        .section-1 {
            z-index: 2;
            min-height: 100vh;
            gap: 50px;
            margin-top: -200px;


            @media screen and (max-width: 1200px) {
                flex-direction: column;
                height: auto;
            }

            .block {

                @media screen and (max-width: 1200px) {
                    max-width: 600px;
                    margin: auto;
                }

                h2 {

                    @media screen and (max-width: 1200px) {
                        width: min-content;
                        margin: auto;
                        text-align: center;

                        @media screen and (max-width: 1200px) {
                            margin-top: 120px;
                        }
                    }


                }

            }



        }

        .section-2 {

            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                height: auto;

            }

            .clock {
                div {
                    @media screen and (max-width: 1200px) {
                        height: 260px !important;
                        margin-top: 50px !important;

                    }
                }
            }
        }


        .section-3 {


            @media screen and (max-width: 1200px) {
                flex-direction: column;
                height: auto;

            }

            .block {

                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                }

                .clock {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    text-align: left;

                    h2 {
                        display: flex;
                        gap: 15px;
                        line-height: 100px;
                        margin: 0;
                        text-align: left;

                        &:first-of-type {
                            span {
                                color: $white;
                            }
                        }

                        &:last-of-type {
                            span {
                                color: $white;
                            }
                        }


                        span {
                            color: $yellow;
                            display: inline-block;
                        }

                        .centerDots {
                            height: 120px;
                        }


                    }

                    div {
                        @media screen and (max-width: 1200px) {
                            height: 140px !important;
                            margin-top: 50px !important;
                            transform: scale(1.5);

                        }
                    }
                }


            }

        }


        .section-4 {
            display: flex;
            /* Allows horizontal layout */
            overflow-x: scroll;
            /* Enables horizontal scrolling */
            white-space: nowrap;
            /* Prevents text wrapping to the next line */
            width: 100%;
            /* Ensures it takes up the full width */
            transition: position 0.3s ease;
            /* Smooth transition for sticky behavior */
            height: 100vh;
            padding: 0;
            align-items: center;
            max-width: none;
            background-color: $background-color;


            &::-webkit-scrollbar {
                display: none;
            }

            @media screen and (max-width: 1200px) {
                position: static !important;
            }

            .bigText {
                flex: 0 0 100vw;
                /* Ensures each block takes up full screen width */
                padding: 20px;

                @media screen and (max-width: 1200px) {
                    flex: 0;
                }

                h2 {
                    font-size: 280px;
                    color: $white;
                    white-space: nowrap;
                    font-family: 'Lastik';
                    margin: 0;

                    span {
                        position: relative;

                        .participants {
                            position: absolute;
                            top: -110px;
                            left: 0;
                        }

                        .observers {
                            position: absolute;
                            bottom: -110px;
                            left: 0;
                        }
                    }

                    .space {
                        width: 50px;
                        display: inline-block;
                    }
                }
            }
        }

        // .section-4noScroll {
        //     display: flex;
        //     /* Prevents text wrapping to the next line */
        //     width: 100%;
        //     /* Ensures it takes up the full width */
        //     transition: position 0.3s ease;
        //     /* Smooth transition for sticky behavior */
        //     height: 100vh;
        //     padding: 0;
        //     align-items: center;
        //     max-width: none;
        //     background-color: $background-color;
        //     overflow-x: scroll;


        //     .bigText {
        //         flex: 0 0 100vw;
        //         /* Ensures each block takes up full screen width */
        //         padding: 20px;

        //         @media screen and (max-width: 1200px) {
        //             flex: 0;
        //         }

        //         h2 {
        //             font-size: 280px;
        //             color: $white;
        //             white-space: nowrap;
        //             font-family: 'Lastik';
        //             margin: 0;

        //             span {
        //                 position: relative;

        //                 .participants {
        //                     position: absolute;
        //                     top: -110px;
        //                     left: 0;
        //                 }

        //                 .observers {
        //                     position: absolute;
        //                     bottom: -110px;
        //                     left: 0;
        //                 }
        //             }

        //             .space {
        //                 width: 50px;
        //                 display: inline-block;
        //             }
        //         }
        //     }
        // }


        // .sticky {
        //     position: fixed;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     z-index: 10;
        //     width: 100%;
        //     overflow-x: scroll;
        //     overflow-y: hidden;
        //     /* Prevent vertical scrolling */
        // }




        .containerScroll {
            position: relative;
            width: 100%;
            min-height: 100vh;

            .space-holder {
                position: relative;
                width: 100%;

                .sticky {
                    position: sticky;
                    top: 0;
                    height: 100vh;
                    width: 100%;
                    overflow-x: hidden;
                    overflow-y: hidden;

                    .horizontal {
                        position: absolute;
                        height: 100%;
                        will-change: transform;

                        .section-4noScroll {
                            position: relative;
                            height: 100%;
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: flex-start;
                            align-items: center;


                            .bigText {
                                flex: 0 0 100vw;
                                /* Ensures each block takes up full screen width */
                                padding: 20px;

                                @media screen and (max-width: 1200px) {
                                    flex: 0;
                                }

                                h2 {
                                    font-size: 280px;
                                    color: $white;
                                    white-space: nowrap;
                                    font-family: 'Lastik';
                                    margin: 0;
                                    margin-right: 140px;

                                    span {
                                        position: relative;

                                        .participants {
                                            position: absolute;
                                            top: -110px;
                                            left: 0;
                                        }

                                        .observers {
                                            position: absolute;
                                            bottom: -110px;
                                            left: 0;
                                        }
                                    }

                                    .space {
                                        width: 50px;
                                        display: inline-block;
                                    }
                                }
                            }
                        }


                    }
                }
            }
        }

        .section-5 {

            max-width: none;
            padding: 0;
            flex-direction: column;


            @media screen and (max-width: 1200px) {
                min-height: 100vh;

            }

            h2 {
                line-height: 120px;
            }

            .block {}

            .cloudPosition {

                @media screen and (max-width: 800px) {
                    margin-top: -100px;

                }

            }
        }

        .section-6 {
            position: relative;
            padding: 30px;
            overflow: visible;
            box-sizing: border-box;
            max-width: none;
            padding: 0;
            flex-direction: column;

            @media screen and (max-width: 800px) {
                overflow: hidden;
                // padding-bottom: 200px;
            }

            .downloadWrap {
                max-width: 400px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                flex-direction: column;
                top: 50px;
                left: 50%;
                transform: translate(-50%, 0%);
                gap: 20px;

                label {
                    color: $white;
                    font-family: $body-font;
                    font-size: 12px;
                }

                div {
                    display: flex;
                    gap: 10px;

                    img {
                        width: 100px;
                    }
                }



            }

            .block {

                @media screen and (max-width: 800px) {}


                .pointsWrap {
                    .textWrap {

                        h2 {
                            @media screen and (max-width: 800px) {

                                font-size: 56px;
                                line-height: 56px;

                            }
                        }

                        .backgroundImage {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: -1;




                            div {

                                @media screen and (max-width: 800px) {
                                    height: 300px !important;
                                }

                            }
                        }


                        .signUp {
                            width: 100%;
                            max-width: 400px;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            margin-top: 20px;

                            #mc_embed_shell {
                                width: 100%;

                                #mc_embed_signup {


                                    .validate {

                                        #mc_embed_signup_scroll {

                                            h3 {}

                                            .mc-field-group {
                                                width: 100%;
                                                display: block;
                                                position: relative;
                                                height: 70px;

                                                label {
                                                    display: none;
                                                }

                                                img {
                                                    width: 100%;
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                }

                                                input {
                                                    background-color: transparent;
                                                    width: 100%;
                                                    height: 70px;
                                                    box-sizing: border-box;
                                                    border: none;
                                                    padding: 0 15px;
                                                    position: relative;
                                                    z-index: 2;
                                                    color: $white;
                                                    font-family: $body-font;
                                                    font-size: 18px;

                                                    @media screen and (max-width: 470px) {
                                                        height: 65px;

                                                    }


                                                    &::placeholder {
                                                        color: $white; // Change this to your desired placeholder color
                                                        opacity: 1; // Ensures full visibility
                                                    }

                                                    &:active {
                                                        border: none;
                                                        outline: none;
                                                    }

                                                    &:focus {
                                                        border: none;
                                                        outline: none;
                                                    }
                                                }

                                            }

                                            .mce-responses {}

                                            .clear {
                                                position: relative;
                                                width: 100%;
                                                max-width: 100px;
                                                margin-top: 15px;

                                                .button {
                                                    background-color: transparent;
                                                    position: relative;
                                                    border: none;
                                                    text-align: center;
                                                    width: 100%;
                                                    max-width: 80px;
                                                    z-index: 2;
                                                    cursor: pointer;
                                                    height: 41px;
                                                    font-size: 16px;
                                                    font-family: $body-font;
                                                }

                                                img {
                                                    width: 100%;
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                }
                                            }



                                        }

                                    }
                                }
                            }



                        }
                    }
                }
            }


        }

        .comingSoonWrap {
            height: 100vh;
            overflow: initial;
        }

        .cloudPositionSection7 {
            margin-bottom: -1px;


            @media screen and (min-width: 1980px) {
                display: none;
            }

            @media screen and (max-width: 800px) {
                margin-top: -200px;
            }




        }

        .cloudPosition {

            @media screen and (min-width: 1980px) {
                display: none;
            }
        }

        .section-7 {
            position: relative;
            padding: 0;
            max-width: none;
            background-color: #313131;

            .background {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;

                @media screen and (max-width: 1200px) {
                    display: none;

                }
            }

            .block {
                .coachesWrap {}
            }


        }

    }

    .comingSoon {
        margin-top: 200px;
        background-color: $background-color;
    }


}

footer {
    width: 100%;
    background-color: $black;
    position: relative;

    .footercontent {
        box-sizing: border-box;
        display: flex;
        gap: 100px;
        padding: 100px 50px;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        h3 {
            font-family: 'Lastik';
            color: $yellow;
            font-size: 24px;
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                margin-bottom: 20px;
                font-size: 16px;
                font-family: 'DM Sans';

                a {
                    text-decoration: none;
                    color: $white;
                    font-weight: 300;

                    &:hover {
                        color: $yellow;
                    }
                }

                label {
                    color: $white;
                    font-weight: 300;

                }
            }
        }

        .column {
            .signUp {
                width: 100%;

                #mc_embed_shell {

                    #mc_embed_signup {

                        .validate {

                            #mc_embed_signup_scroll {

                                h3 {}

                                .mc-field-group {
                                    width: 100%;
                                    max-width: 300px;
                                    display: block;
                                    position: relative;
                                    height: 44px;

                                    label {
                                        display: none;
                                    }

                                    img {
                                        width: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }

                                    input {
                                        background-color: transparent;
                                        width: 100%;
                                        height: 44px;
                                        box-sizing: border-box;
                                        border: none;
                                        padding: 0 10px;
                                        position: relative;
                                        z-index: 2;
                                        color: $white;
                                        font-family: $body-font;

                                        @media screen and (max-width: 1200px) {
                                            height: 50px;

                                        }


                                        &::placeholder {
                                            color: $white; // Change this to your desired placeholder color
                                            opacity: 1; // Ensures full visibility
                                        }

                                        &:active {
                                            border: none;
                                            outline: none;
                                        }

                                        &:focus {
                                            border: none;
                                            outline: none;
                                        }
                                    }

                                }

                                .mce-responses {}

                                .clear {
                                    position: relative;
                                    width: 100%;
                                    max-width: 80px;
                                    margin-top: 15px;

                                    .button {
                                        background-color: transparent;
                                        position: relative;
                                        border: none;
                                        text-align: center;
                                        width: 100%;
                                        max-width: 80px;
                                        z-index: 2;
                                        cursor: pointer;
                                        height: 33px;
                                        font-family: $body-font;
                                    }

                                    img {
                                        width: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                }



                            }

                        }
                    }
                }



            }


        }

        .logoWrap {

            position: absolute;
            right: 100px;

            @media screen and (max-width: 600px) {
                position: static;
            }



            .logo {
                margin-top: 50px;


                img {
                    max-width: 150px;
                }
            }
        }


    }

    .footerLower {
        padding: 20px 50px;
        box-sizing: border-box;
        color: $white;
        font-size: 16px;
        font-weight: 300;
        font-family: 'DM Sans';

        a {
            text-decoration: none;
            color: $white;

            &:hover {
                color: $yellow;
            }
        }
    }
}


.termsWrap {
    background-color: $yellow;
    width: 100%;

    @media screen and (max-width: 800px) {
        padding-top: 50px;
    }

    .align {
        display: flex;
        width: 100%;
        padding: 30px 60px;
        max-width: 800px;
        margin: auto;
        box-sizing: border-box;

        @media screen and (max-width: 1200px) {
            padding: 30px;

        }


        table {
            font-family: $body-font;
            font-size: 20px;

            h1 {
                font-family: $title-font;
            }
        }

        .termsWrap {
            font-family: $body-font;
            font-size: 20px;

            h1 {
                font-family: $title-font;
            }
        }

        .contactUs {
            width: 100%;

            #mc_embed_shell {
                width: 100%;


                #mc_embed_signup {
                    width: 100%;


                    .validate {
                        width: 100%;


                        #mc_embed_signup_scroll {

                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            width: 100%;

                            h3 {}

                            .mc-field-group {
                                width: 100%;
                                display: block;
                                position: relative;

                                label {
                                    display: none;
                                }

                                img {
                                    width: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }

                                input {
                                    background-color: $grey;
                                    width: 100%;
                                    box-sizing: border-box;
                                    border: none;
                                    padding: 20px;
                                    position: relative;
                                    z-index: 2;
                                    color: $white;
                                    font-family: $body-font;
                                    border-radius: 10px;
                                    font-size: 18px;
                                    border: 1px solid $grey;

                                    &:active {
                                        border: none;
                                        outline: none;
                                    }

                                    &:focus {
                                        border: none;
                                        outline: none;
                                    }

                                    &::placeholder {
                                        color: $white; // Change this to your desired placeholder color
                                        opacity: 1; // Ensures full visibility
                                    }
                                }

                                select {
                                    background-color: $grey;
                                    border: 1px solid $grey;
                                    width: 100%;
                                    box-sizing: border-box;
                                    border: none;
                                    padding: 20px;
                                    position: relative;
                                    z-index: 2;
                                    color: $white;
                                    font-family: $body-font;
                                    border-radius: 10px;
                                    font-size: 18px;

                                    &:active {
                                        border: none;
                                        outline: none;
                                    }

                                    &:focus {
                                        border: none;
                                        outline: none;
                                    }
                                }


                                .mce_inline_error {

                                    border: 2px solid $red;

                                }

                            }

                            .mce-responses {}

                            .clear {
                                position: relative;
                                width: 100%;

                                .button {
                                    background-color: $green;
                                    position: relative;
                                    border: none;
                                    text-align: center;
                                    width: 100%;
                                    cursor: pointer;
                                    font-family: $body-font;
                                    padding: 20px;
                                    font-size: 16px;
                                    border-radius: 10px;
                                }

                                img {
                                    width: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }



                        }

                    }
                }
            }

        }
    }

}

#mc_embed_signup div.mce_inline_error {
    background-color: $red !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    font-family: $body-font;
    font-weight: 400 !important;
}

.thankyouWrap {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrap {
        color: $white;
        display: flex;
        width: 100%;
        max-width: 350px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        margin: auto;
        flex-direction: column;
        background-image: url(../images/background.svg);
        background-size: cover;
        border-radius: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;


        .coachAnimation {
            height: 300px;
            width: 200px;
        }

        h1 {
            width: 100%;
            text-align: center;
            font-family: $title-font;
            margin-bottom: 0;
            font-size: 40px;
        }

        p {
            font-family: $body-font;
            max-width: 300px;
            text-align: center;
            font-size: 20px;
        }

        .pointsWrap {
            height: 100px;
            width: 100px;
            display: block;
        }

        a {
            background-color: $grey;
            color: $white;
            text-decoration: none;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: $body-font;
            margin-top: 50px;
        }
    }
}

.error-message {
    color: $white;
    font-family: $body-font;
    position: absolute;
    right: 0;
    max-width: 300px;
    background-color: $red;
    border-radius: 6px;
    padding: 9px;
    box-sizing: border-box;

    @media screen and (max-width: 470px) {
        top: 69px;
    }
}

.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-select select {
    width: 100%;
    appearance: none;
    /* Hides default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 30px;
    /* Space for custom arrow */
}

.custom-select img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 100%;
    position: absolute;
    top: 33px !important;
    right: 25px !important;
    left: auto !important;
    z-index: 3;
    max-width: 25px;
    /* Prevent clicking on the image */
}
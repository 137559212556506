﻿//BRAND COLOURS
$background-color: #1E1E1E;
$yellow: #FFD939;
$green: #5FCF7D;
$red: #EB3B2F;
$purple: #C879F2;
$blue: #8EA8FF;
$white: #F3F3F3;
$black: #1E1E1E;
$grey: #313131;

//FONTS
$title-font: 'Lastik', serif;
$body-font: 'DM Sans', serif;